class Constant {
  static INTRANET = 'intranet';
  static INTERNET = 'internet';
  static JP_MORGAN_CHASE_NAME = 'JPMorgan Chase';
  static JP_MORGAN_CHASE_LNIK = 'https://www.jpmorganchase.com';
  static JP_MORGAN_NAME = 'J.P. Morgan';
  static JP_MORGAN_LINK = 'https://www.jpmorgan.com';
  static CHASE = 'Chase';
  static CHASE_LINK = 'https://www.chase.com';

  static HOME_PAGE = 'My Health Home';
  static HOME_PAGE_URL = '/pc/MyHealth_2_0_Home1.aspx';
  static BENIFITS_ENROLMENT_PAGE = 'Benefits Enrollment';
  static BENIFITS_ENROLMENT_PAGE_URL =
    '/pc/MyHealth_2_0_Benefits_Enrollment.aspx';

  static ACCOUNTS_PAGE = 'Medical, Rx, MRA & Spending Accounts';
  static ACCOUNTS_PAGE_URL = '/pc/MyHealth_2_0_Accounts.aspx';
  static WELLNESS_PAGE = 'Wellness Activities & Services';
  static WELLNESS_PAGE_URL = '/pc/MyHealth_2_0_Wellness.aspx';
  static INSURANCE_PAGE = 'Dental, Vision, and Other Insurance';
  static INSURANCE_PAGE_URL = '/pc/MyHealth_2_0_Dental.aspx';
  /*static MEDICAL_SPECIALITY_PAGE = 'Medical Specialty Services';*/
  static MEDICAL_SPECIALITY_PAGE = 'Expert Medical Advice, Maternity, LGBTQ+, & Family Building';
  static MEDICAL_SPECIALITY_PAGE_URL = '/pc/MyHealth_2_0_Speciality.aspx';
  static NOT_ENROLLED_MEDICAL_PAGE = 'Not Enrolled in JPMC Medical?';
  static NOT_ENROLLED_MEDICAL_PAGE_URL =
    '/pc/Not_enrolled_in_the_JPMC_medical_plan.aspx';

  static LTD_HOME_PAGE_URL = '/pc/LTD_E.aspx';
  static SEVRCR_HOME_PAGE_URL = '/pc/COBRA_E.aspx';
  static RETIRE_HOME_PAGE_URL = '/pc/Retirees_E.aspx';
  static HOME_PAGE_LOAD = '/pc/Home.aspx';

  static FETCH_MONETA_USER = '/pc/mh/api/user';
  static FETCH_EMP_PROFILE = '/pc/mh/api/employee/profile';
  static GET_EMP_DETAILS = '/pc/mh/api/employee';

  static EMPL_STATUS_CODE_MAP = new Map([
    // Codes mapping to ACTIVE
    ['ACTIVE', 'ACTIVE'],
    ['LADSNP', 'ACTIVE'],
    ['LADSWP', 'ACTIVE'],
    ['LAFMNP', 'ACTIVE'],
    ['LAFMWP', 'ACTIVE'],
    ['LAMLNP', 'ACTIVE'],
    ['LAMLWP', 'ACTIVE'],
    ['LAMXNP', 'ACTIVE'],
    ['LAPRNP', 'ACTIVE'],
    ['LAPRWP', 'ACTIVE'],
    ['LAWCNP', 'ACTIVE'],
    ['LAWCWP', 'ACTIVE'],
    ['LOANP', 'ACTIVE'],
    ['LOAWP', 'ACTIVE'],
    // Codes mapping to LTD
    ['LTD', 'LTD'],
    // Codes mapping to SEVRCR
    ['SEVRCR', 'SEVRCR'],
    ['SSA<25', 'SEVRCR'],
    ['TERM', 'SEVRCR'],
    ['TRMWB', 'SEVRCR'],
    // Codes mapping to RETIRE
    ['RETIRE', 'RETIRE'],
    ['ACTRET', 'RETIRE'],
    ['RETWP', 'RETIRE'],
    ['SSA>25', 'RETIRE'],
    ['SSRET', 'RETIRE']
  ]);
}
export default Constant;
