import React, {Component} from 'react';
import './CookiesPolicy.scss';
import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
class CookiesPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDecisionalModal1:false
    };

  }

  toggle() {
    this.setState({
      showDecisionalModal2: !this.state.showDecisionalModal2
    });
  }

  render() {
    return (<li>
        <Modal isOpen={this.state.showDecisionalModal2} toggle={this.toggle} >
          <div className="modal-header customHeader">
            <h4 className="modal-title">Terms & Conditions of Use</h4>
            <button data-testid='headerButton' type="button" className="close" aria-label="Close" onClick={() => this.toggle()} />
          </div>
          <ModalBody>
            <div>
              <p>
                <hr/>
                The JPMorgan Chase Benefits Program is available to employees who meet the benefits-eligibility criteria
                and who are employed by JPMorgan Chase Co. or one of its subsidiaries to the extent that such subsidiary
                has adopted the JPMorgan Chase Benefits Program.<br/><br/>This site does not include all of the details
                contained in the applicable insurance contracts, plan documents, and trust agreements. If there is any
                discrepancy between this information and the governing documents, the governing documents will control.
                JPMorgan Chase & Co. expressly reserves the right to amend, modify, reduce, terminate, or change its
                benefits and plans at any time.<br/><br/>Please Note: The records on file with Corporate Human Resources,
                as well as the official plan documents or contracts, will always govern the availability and amounts of
                your actual benefits. As such, you should not make any decisions based solely upon the information
                contained on this site.<br/><br/>By using this site, you expressly agree to the above terms and
                conditions.<br/><br/><b>Cookie Policy</b><br/>This site uses cookies to store information on your
                computer. This policy explains how we use cookies and may be amended, from time to time, without notice.
                To ensure that you are using this site with full and up-to-date information of how we use cookies please
                review this policy regularly as any amended policy will be updated on the site. By using this site you
                agree to the placement of cookies on your computer in accordance with the terms of this policy. If you do
                not wish to accept cookies from this site please either disable cookies or refrain from using this
                site.<br/><br/><b>What are Cookies?</b><br/><br/>A cookie is a text-only string of information that a
                website transfers to the cookie file of the browser on your computer's hard disk so that the website can
                recognize you when you revisit and remember certain information about you. This can include which pages
                you have visited, choices you have made from menus, any specific information you have entered into forms
                and the time and date of your visit.<br/><br/><b>Types of Cookies</b><br/><br/>There are two main types of
                cookies:<br/><br/>Session cookies: these are temporary cookies that expire at the end of a browser
                session; that is, when you leave the site. Session cookies allow the website to recognize you as you
                navigate between pages during a single browser session and allow you to use the website most efficiently.
                For example, session cookies enable a website to remember that a user has placed items in an online
                shopping basket.<br/><br/>Persistent cookies: in contrast to session cookies, persistent cookies are
                stored on your equipment between browsing sessions until expiry or deletion. They therefore enable the
                website to "recognize" you on your return remember your preferences and tailor services to you.<br/><br/>In
                addition to session cookies and persistent cookies, there may be other cookies which are set by the
                website which you have chosen to visit, such as this website, in order to provide us or third parties with
                information.<br/><br/>Our use of Cookies<br/><br/>
                We currently use, and may use in the future, the following types of cookies on this website.<br/><br/>We
                use session cookies to:<br/><br/>help us maintain security and verify your details whilst you use the
                website as you navigate from page to page, which enables you to avoid having to re-enter your details each
                time you enter a new page.<br/><br/>We use persistent cookies to:<br/><br/>help us recognize you as a
                unique user when you return to our website so that you do not have to input your details multiple times as
                you move between our pages or services<br/><br/>remember how you have customised your use of this site,
                such as your preferred currency and time zone<br/><br/>collect and compile anonymous, aggregated
                information for statistical and evaluation purposes to help us understand how users use the website and
                help us improve the structure of our website.<br/><br/>Many cookies are designed to give you optimal usage
                of the web. For example, we use cookies to enable you to improve your user experience when using our
                website, e.g. a cookie which recognizes if your browser supports specific technology features. This helps,
                for example, in enabling web pages to be loaded more quickly when you request the download of a large
                file.<br/><br/>In addition to cookies which send information to us, we also use cookies which collect
                information and send it to third parties. An example of this is Google Analytics. Please check the
                relevant third party website for more information about their use of cookies. Where this site allows such
                cookies to be set or you access other websites from this site using the links provided, the operators of
                these websites will use cookies in accordance with their own cookies policy, which may differ from
                ours.<br/><br/>As with first party cookies, you are able to block third party cookies through your browser
                settings.<br/><br/>Some of our cookies may collect and store your personal information, such as your name
                or email address. We are committed to respecting and protecting your privacy and will ensure that all
                personal information collected by us is kept and treated in accordance with our privacy policy. A link to
                this policy is available within the footer of this portal or alternatively at our jpmorgan.com
                website.<br/><br/>
                Refusing Cookies on this Site<br/><br/>Most browsers are initially set to accept cookies. However, you
                have the ability to disable cookies if you wish, generally through changing your internet software
                browsing settings. It may also be possible to configure your browser settings to enable acceptance of
                specific cookies or to notify you each time a new cookie is about to be stored on your computer enabling
                you to decide whether to accept or reject the cookie. To manage your use of cookies there are various
                resources available to you, for example the "Help" section on your browser may assist you. You can also
                disable or delete the stored data used by technology similar to cookies, such as Local Shared Objects or
                Flash cookies, by managing your browser's "add-on settings or visiting the website of its manufacturer. As
                our cookies allow you to access some of our website's essential features we recommend that you leave
                cookies enabled, otherwise, if cookies are disabled, it may mean that you experience reduced functionality
                or will be prevented from using this site altogether.
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button data-testid='okButton' color="primary" onClick={() => this.toggle()}>OK</Button>
          </ModalFooter>
        </Modal>
        <a
          href="#"
          onClick={(e) =>{ this.setState({showDecisionalModal2: !this.state.showDecisionalModal2}); e.preventDefault();}}>Cookies Policy</a>
      </li>
    );
  }
}

export default CookiesPolicy;
