import React, {Component} from 'react';
import { Route, Switch } from 'react-router-dom';
import heart from 'assets/images/Heart_title_icon_white.svg';

import {
  TuxGlobalHeader
} from '@tuxedo/react-component-library';

import MainComponent from "../../MainComponent";
class PageExtLayout extends Component {
  constructor(props) {
    super(props);

    this.toggleheaderMenu = this.toggleheaderMenu.bind(this);

    this.state = {
      employeeType: ''
    };
  }

  toggleMenu() {
    this.setState({menuExpanded: !this.state.menuExpanded});
  }

  toggleheaderMenu() {
    this.setState({headerMenuOpen: !this.state.headerMenuOpen});
  }

  componentDidMount() {
    if(localStorage.getItem("USERTYPE")){
      this.setState({employyeType: localStorage.getItem("USERTYPE")});
    }
  }

  render() {
    return (
      <div>
        <div className={`main ${this.state.menuExpanded ? 'menuExpanded' : ''}`}>
          <TuxGlobalHeader>
            <div className="left_side">
              <a className="navbar-brand center" href="#">
                {/*Kept for testing the redirect link*/}
                {/*<a href={'/hrgway/api/myhealth/1.0/profile/redirect/wellness'} target="_blank">Benefit Web Center</a>*/}
                <img className="img-fluid imgDisplay" src={heart} alt="JPMC logo"
                />
                <span className="text-white">My Health</span>
              </a>
            </div>
          </TuxGlobalHeader>

          <main className="content_body">
            <Switch>
              <Route path="/*" component={MainComponent}/>
            </Switch>
          </main>
        </div>
      </div>

    );
  }
}

export default PageExtLayout;
