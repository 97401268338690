import React, {Component} from 'react';
import './LegalTerms.scss';
import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
class LegalTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDecisionalModal1:false
    };
    this.toggle= this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      showDecisionalModal1: !this.state.showDecisionalModal1
    });
  }

  render() {
    return (<li>
        <Modal isOpen={this.state.showDecisionalModal1} toggle={this.toggle}>
          <div className="modal-header customHeader">
            <h4 className="modal-title">My Health</h4>
            <button data-testid='headerButton' type="button" className="close" aria-label="Close" onClick={() => this.toggle()} />
          </div>
          <ModalBody>
            <div>
              <hr/>
              <p>
                The JPMorgan Chase U.S. Benefits Program is available to most employees on a U.S. payroll who are
                regularly scheduled to work 20 hours or more a week and who are employed by JPMorgan Chase & Co. or one of
                its subsidiaries to the extent that such subsidiary has adopted the JPMorgan Chase U.S. Benefits
                Program.<br/><br/>The information accessed on or through this website does not include all of the details
                contained in the applicable insurance contracts, plan documents, trust agreements, and other governing
                documents.<br/><br/>If there is any discrepancy between this information and the governing documents, the
                governing documents will control. JPMorgan Chase & Co. expressly reserves the right to amend, modify,
                reduce, change or terminate its benefits and plans at any time.<br/>Participation in the JPMorgan Chase
                U.S. Benefits Program does not create a contract or guarantee of employment between JPMorgan Chase and any
                individual. JPMorgan Chase or you may terminate the employment relationship at any time.</p>
            </div>
          </ModalBody>
          <ModalFooter >
             <Button data-testid='okButton' color="primary" onClick={() => this.toggle()}>OK</Button>
          </ModalFooter>
        </Modal>
        <a href="#" onClick={(e) => {this.setState({showDecisionalModal1: !this.state.showDecisionalModal1}); e.preventDefault();} }>Legal Terms
          and Condition</a>
      </li>
    );
  }
}

export default LegalTerms;
