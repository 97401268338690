import React, {Component} from 'react';
import {TuxAlert} from '@tuxedo/react-component-library';

class Error extends Component {


  render() {
    return (
      <p>
        <TuxAlert type="warning" heading="Oops! Not Accessible" dismissable>
        <div>
          <table style={{border: '0px', cellSpacing: '0px', cellPadding: '6px', width: '100%', bgColor:'#ffffff'}} >
            <tbody>
              <tr>
                <td style={{bgColor: '#ffffff'}} >
                  <strong>My Health - Page Not Accessible</strong>
                  <p>
                    <br />
                      <strong> Please Note:</strong>  My Health is available only to current and former U.S. domestic employees participating in the JPMorgan Chase U.S. Benefits Program.
                  </p>
                  <p>
                      <p style={{fontFamily:'Arial'}}>Our records indicate that you are not currently participating in one of the following plans:  JPMorgan Chase Medical Plan, Health Care and/or Child/Elder Care Spending Accounts, therefore, certain links from the My Health page are not available to you.&nbsp; If
            you have recently enrolled in the plan as a
            newly-hired or newly-eligible employee, it may
            take several days before you are able to access
            these links.
                      </p>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </TuxAlert>
      </p>
    );
  }

}
export default Error;
