const initialState = {
  sid: '',
  empType: '',
  empData: {},
  empState: 'DEFAULT',
  vendor: ''
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SID':
      return {
        ...state,
        sid: action.value
      };
    case 'EMPTYPE':
      return {
        ...state,
        empType: action.value
      };
    case 'EMPDATA':
      return {
        ...state,
        empData: action.value
      };
    case 'EMPSTATE':
      return {
        ...state,
        empState: action.value
      };
    case 'VENDOR':
      return {
        ...state,
        vendor: action.value
      };
    default:
      return state;
  }
}

export default userReducer;
