import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import './App.scss';
import RouteMapper from "./RouteMapper";
import {BrowserRouter} from "react-router-dom";
import SiteCatalystComponent from "./Component/SiteCatalystComponent";
//import Redirection from "./assets/ext_lib/Redirection";
import rootReducer from './mainReducer';


let url;
let host = "";
//Redirection();

const isValidUrl = urlString=> {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
  return !!urlPattern.test(urlString);
};

if(((window.location.pathname).indexOf(".aspx") > -1 || (window.location.pathname).endsWith("/hp/home") > -1 || (window.location.pathname).endsWith("/pc") > -1) && (window.location.pathname).indexOf("/hrgway") > -1 && (window.location.href).indexOf("/hrgway") > -1)
{
  const path =  window.location.href.replace('/hrgway','');
  if(isValidUrl(path)) {
    var validator = require('validator');
    var escaped_string = validator.blacklist(path,"<>");
    window.location.href = escaped_string;
  }
}
const store = configureStore({ reducer: rootReducer });
const getUrl = (page) => {
  host = "";
  if (page !== undefined && page.indexOf("/downloadFile/") !== -1) {
    if (page.indexOf("http") !== -1) {
      const splitUrl = page.split("/");
      host = splitUrl[0] + "//" + splitUrl[2];
      page = page.replace(host, '');
    }
    if (page.indexOf("/pc/api/office365/1.0/downloadFile/") !== -1) {
      page = page.replace("/pc/api/office365/1.0/downloadFile/", "");
      page = page.replace("?appType=MH", "");
      url = "/pc/api/myhealth/1.0/downloadFile/" + page;
    } else {
      page = page.replace("/api/office365/1.0/downloadFile/", "");
      page = page.replace("?appType=MH", "");
      url = "/api/myhealth/1.0/downloadFile/" + page;
      if(page.indexOf("/hrgway/pc/api/myhealth/1.0/downloadFile")!== -1){//changes made on 11/16/2022
          url=page;
      }
    }

  } else if (page !== undefined && page.indexOf("/pc/api/office365/") !== -1) {
    if (page.indexOf("http") !== -1) {
      const splitUrl = page.split("/");
      host = splitUrl[0] + "//" + splitUrl[2];
      page = page.replace(host, '');
    }
    page = page.replace("/pc/api/office365/1.0/pageContent/", "");
    page = page.replace("?appType=MH", "");
    url = "/pc/api/myhealth/" + page;

  } else if (page !== undefined && page.indexOf("/api/office365/") !== -1) {
    if (page.indexOf("http") !== -1) {
      const splitUrl = page.split("/");
      host = splitUrl[0] + "//" + splitUrl[2];
      page = page.replace(host, '');
    }
    page = page.replace("/api/office365/1.0/pageContent/", "");
    page = page.replace("?appType=MH", "");
    url = "/api/myhealth/1.0/pageContent/" + page;

  } else if (page !== undefined && page.indexOf("redirect") !== -1) {
    url = page;
  } else if (page !== undefined && page.indexOf("/file/media/") !== -1) {
    url = page;
  } else if (page !== undefined && page.indexOf("/pc/") !== -1) {
    if (page.indexOf("http") !== -1) {
      const splitUrl = page.split("/");
      host = splitUrl[0] + "//" + splitUrl[2];
    }
    if (host.length > 0 && host !== window.location.host) {
      url = page;
    } else {
      page = page.replace(host, '');
      page = page.replace("/pc/", "");
      url = "/pc/api/myhealth/" + page;
    }
  }else{
    if (page.indexOf("http") !== -1) {
      const splitUrl = page.split("/");
      host = splitUrl[0] + "//" + splitUrl[2];
    }
    url = page;
  }
};

const handleClick=(e)=>{
  const getUserInfo = store.getState()?.userReducer;
  let event="Page Load";
  let page = e.target?.getAttribute("href");
  let onClick = e.target.innerText;
  if((page == null || page === undefined) && (e?.target != null) && (e?.target?.parentElement!=null)){
    page = e?.target?.parentElement?.href; //e.target.parentElement.href;
    if(page===undefined || page==null){
      page=e?.target?.ownerDocument?.activeElement?.href;
    }
    onClick = e?.target?.parentElement?.innerText;

  }
  if(page) {
    getUrl(page);
    host = host.replace("https://","");
    host = host.replace("http://","");
    let location_host = window.location.host;
    location_host = location_host.replace("https","");
    location_host = location_host.replace("http","");
    if(host.length>0 && host!==location_host){
      event="Exit";
    }
    else if((url.indexOf("aspx")!==-1) &&(url.indexOf("myhealth")!==-1) && !(url.indexOf("/file/media/")!==-1)) {
      event = "Page Load";
    } else if ((url.indexOf("downloadFile")!==-1) || ((url.indexOf("/file/media/")!==-1)&& (url.indexOf(".pdf")!==-1))) {
      event = "Download";
    }else if(url==="#"){
      event="";
    }else if(url==="/Act_tab_Spouses_Domestic_Partners_np.aspx"
      || url==="/Act_tab_Spouses_Domestic_Partners_Category_np.aspx"
      || url === "/Act_tab_Spouses_Domestic_Partners_Category2_np.aspx"
      || url==="/Retire_Medicare_EligibleLTD_np.aspx"){
      event="Page Load";
    }else if(url.indexOf("mailto")!==-1){
      event="Exit";
    }
    else{
      event="Page Load";
    }

    //history.push(url);
    if(event.length>0){
      SiteCatalystComponent.tracking(event,url,onClick, getUserInfo);
    }

  }
};

window.addEventListener("click", handleClick);
/*document.querySelectorAll('a').forEach(link=>{
  link.addEventListener("click",()=>{
    console.log("link clicked");
  })
})*/
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <RouteMapper />
    </BrowserRouter>
  </Provider>, document.getElementById('root')
);
