import Constant from "./Constant";
class SiteCatalystComponent{

  static  tracking(event,pageId,onClick, userInfo){
    if(event==="Page Load"){
      this.pageLoad(event,pageId,onClick, userInfo);
    }else if(event==="Download"){
      this.download(event,pageId,onClick);
    }else{
      this.exit(event,pageId,onClick);
    }

  };

    static pageLoad(event,pageId,click,userInfo){
      if(typeof window.aadata === "undefined"){
        window.aadata = [];
      }
      const data = {
        "event":"",
        "pageInfo":{
          "pageName":"",
          "domain":""
        },
        "clickInfo":{
          "linkName":""
        },
        "userInfo":{
          "sid":""
        }
      };
      data.userInfo.sid= userInfo?.sid;
      data.event=event;
      const strNetType = (window?.location?.href?.indexOf('jpmchase.net') !== -1) ? Constant.INTRANET:Constant.INTERNET;
      let array = [];
      let strPageName = pageId;
      array = strPageName?.split("/");
      strPageName = array[array?.length-1];
      strPageName = strPageName?.split(".aspx")[0];
      if(strPageName?.indexOf("MyHealth_2_0_")!==-1){
        strPageName = strPageName?.replace("MyHealth_2_0_","");
        strPageName = strPageName?.replace("Home1","Home");
      }
      data.pageInfo.pageName = strPageName?.replaceAll("_", " ");
      data.pageInfo.domain=strNetType;
      data.clickInfo.linkName=click;
      window.aadata.push(data);
      sessionStorage.setItem("adobeData", JSON.stringify(window?.aadata));

    }

    static download(event,pageId,click,userInfo){
      if(typeof window.aadata === "undefined"){
        window.aadata = [];
      }
      const data = {
        "event":"Download",
        "pageInfo":{
          "pageName":"",
          "domain":""
        },
        "clickInfo":{
          "linkName":""
        },
        "userInfo":{
          "sid":""
        },
        "downloadInfo":{
          "downloadURL":"",
          "downloadName":""
        }
      };
      data.userInfo.sid= userInfo?.sid;
      data.event = event;
      const strNetType = (window?.location?.href.indexOf('jpmchase.net') !== -1) ? "Intranet": "Internet";
      const strPageName = pageId;
      const pageArr  = strPageName.split("/");
      data.downloadInfo.downloadURL=strPageName;
      const fileName =  pageArr[pageArr?.length-1];
      data.downloadInfo.downloadName=fileName;
      let  pageName = window.location.pathname;
      if(pageName.indexOf("MyHealth_2_0_")!==-1) {
        pageName = pageName?.replace("MyHealth_2_0_","");
        pageName = pageName?.replace("Home1","Home");
      }
      let path=pageName?.split(".aspx")[0];
      path = path.split("/");
      data.pageInfo.pageName=path[path.length-1]?.replaceAll("_"," ");
      data.pageInfo.domain = strNetType;
      data.clickInfo.linkName=click;
      window.aadata.push(data);
      sessionStorage.setItem("adobeData", JSON.stringify(window?.aadata));
    }

    static exit(event,pageId,exitName,userInfo){
      if(typeof window.aadata === "undefined"){
        window.aadata = [];
      }
      const data = {
        "event":"",
        "pageInfo":{
          "pageName":"",
          "domain":""
        },
        "clickInfo":{
          "linkName":""
        },
        "userInfo":{
          "sid":""
        },
        "exitInfo":{
          "exitURL":"",
          "exitName":""
        }
      };
      data.userInfo.sid= userInfo?.sid;
      const strNetType = (window?.location?.href?.indexOf('jpmchase.net') !== -1) ? "Intranet": "Internet";
      data.event=event;
      let strPageName = pageId;
      strPageName = strPageName?.replace(".aspx", " ");
      let  pageName = window.location.pathname;
      if(pageName?.indexOf("MyHealth_2_0_")!==-1) {
        pageName = pageName?.replace("MyHealth_2_0_","");
        pageName = pageName?.replace("Home1","Home");
      }
      let path = pageName?.split(".aspx")[0];
      path = path?.split("/");
      let page=path[path.length-1]?.replaceAll("_"," ");
      data.pageInfo.pageName=page;
      data.exitInfo.exitURL = strPageName;
      data.exitInfo.exitName = exitName;
      data.pageInfo.domain = strNetType;
      data.clickInfo.linkName=exitName;
      if(strPageName.indexOf("mailto")!==-1){
        data.exitInfo.exitURL = "Email@"+page;
        data.exitInfo.exitName = "Email@"+page;
        data.clickInfo.linkName="Email@"+page;
      }
      window.aadata.push(data);
      sessionStorage.setItem("adobeData", JSON.stringify(window?.aadata));
    }

}


export default SiteCatalystComponent;
