//import {TuxCarousel} from '@tuxedo/react-component-library';

import axios from 'axios';

class UserComponent {
  static getEmpProfile(url) {
    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default UserComponent;
